function crossTalk<T>(request: string, args: any): Promise<T> {
  return new Promise<T>((resolve) => {
    const id = Math.floor((1 + Math.random()) * 0x10000).toString(16)
    const receiver = (ev: MessageEvent) => {
      if (ev.origin !== document.location.origin) {
        if (
          ev.data &&
          ev.data.answer !== undefined &&
          ev.data.id &&
          ev.data.id === id
        ) {
          window.removeEventListener('message', receiver)
          resolve(ev.data.answer)
        }
      }
    }
    window.addEventListener('message', receiver)
    window.parent.postMessage({ request, args, id }, '*')
  })
}

export { crossTalk }
